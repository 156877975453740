(function ($) {
  Drupal.behaviors.pilotAppLoaderV1 = {
    nodes: {},
    isMobile: Unison.fetch.now().name === 'small',
    attach: function (context, settings) {
      const self = this;

      self.nodes.$container = $('.js-app-loader-v1', context);
      self.nodes.$container.attr('data-is-mobile', self.isMobile ? 'true' : 'false');
      self.nodes.$overlayDiv = $('.js-image-recommender-overlay', context);

      const $container = self.nodes.$container;
      const {
        showLoadingScreen,
        loadOnHash,
        hash,
        loadOnSelector,
        selector,
        selectorEvent,
        selectedPilot,
        useEnv
      } = $container.data();

      Unison.on('change', function (currentBreakPoint) {
        self.isMobile = currentBreakPoint.name === 'small';
        self.nodes.$container.attr('data-is-mobile', self.isMobile ? 'true' : 'false');
      });

      const PILOT_PATH_MAP = {
        image_recognition_search: '/plts/img_rec/<env>/imgrec.dj.min.js'
      };

      if (!PILOT_PATH_MAP[selectedPilot]) {
        return;
      };

      const path = useEnv === 1
        ? PILOT_PATH_MAP[selectedPilot].replace('<env>', settings?.common?.pilot_env)
        : PILOT_PATH_MAP[selectedPilot].replace('/<env>', '');
      const loadingParams = {
        path
      };

      if (loadOnHash === 1) {
        $(window).on('hashchange', () => {
          self.onHashChange(hash, loadingParams, showLoadingScreen);
        });
        self.onHashChange(hash, loadingParams, showLoadingScreen);
      }

      if (loadOnSelector === 1) {
        const $elements = $(selector);

        $elements.each(function () {
          $(this).on(selectorEvent, () => {
            self.checkAndLoadScript(loadingParams, showLoadingScreen);
          });
        });
      }

      if (loadOnHash !== 1 && loadOnSelector !== 1) {
        self.checkAndLoadScript(loadingParams, showLoadingScreen);
      }
    },
    onHashChange: function (hash, loadingParams, showLoadingScreen) {
      const self = this;

      if (location.hash === hash) {
        self.checkAndLoadScript(loadingParams, showLoadingScreen);
      }
    },
    checkAndLoadScript: function (loadingParams, showLoadingScreen) {
      const self = this;
      const scriptId = 'pilot_app_loader';
      const scriptNotAlreadyLoaded = $(`#${scriptId}`).length < 1;

      if (scriptNotAlreadyLoaded) {
        if (self.isMobile && showLoadingScreen === 1) {
          self.openAppOverlay();
        }
        self.load(loadingParams, showLoadingScreen);
      }
    },
    toggleScrollLock: function (state) {
      const $htmlElement = $('html');

      if ($htmlElement.length > 0) {
        $htmlElement.css('overflow', state === 'open' ? 'hidden' : 'visible');
      }
    },
    openAppOverlay: function () {
      const self = this;
      const $overlayDiv = self.nodes.$overlayDiv;

      if ($overlayDiv.length > 0) {
        self.toggleScrollLock('open');
        $overlayDiv.removeClass('hidden');
      }
    },
    closeAppOverlay: function () {
      const self = this;
      const $overlayDiv = self.nodes.$overlayDiv;

      if ($overlayDiv.length > 0) {
        self.toggleScrollLock('closed');
        $overlayDiv.addClass('hidden');
      }
    },
    load: function (loadingParams, showLoadingScreen) {
      const self = this;
      const {
        path
      } = loadingParams;
      const scriptSrc = path;
      const script = document.createElement('script');

      script.src = scriptSrc;
      script.async = true;
      script.defer = true;
      script.id = 'pilot_app_loader';
      document.head.appendChild(script);

      script.onerror = () => {
        if (showLoadingScreen === 1) {
          self.closeAppOverlay();
        }
      };
    }
  };
})(jQuery);
